import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import './LandingPage.css';

const Features = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  return (
    <div className="landing-page">
      <Navbar />
      
      <div className="subpage-container">
        <motion.div 
          className="subpage-header"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h1>TalentLex Features</h1>
          <p>Discover how our tools can help you secure your dream position</p>
        </motion.div>
        
        <motion.div
          className="feature-section"
          initial="hidden"
          animate="visible"
          variants={staggerChildren}
        >
          <motion.div variants={fadeIn} className="feature-block">
            <div className="feature-icon-large">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>
            <h2>AI-Powered Application Review</h2>
            <p>
              Our advanced AI analyzes your application against successful applications from top law firms. 
              Get personalized feedback on your strengths and areas for improvement.
            </p>
            <ul className="feature-list">
              <li>Detailed analysis of your application structure</li>
              <li>Feedback on language, clarity, and persuasiveness</li>
              <li>Specific recommendations for improvement</li>
              <li>Benchmark against successful applications</li>
              <li>Guidance on firm-specific expectations</li>
            </ul>
          </motion.div>

          <motion.div variants={fadeIn} className="feature-block">
            <div className="feature-icon-large">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
              </svg>
            </div>
            <h2>Application Tracking</h2>
            <p>
              Stay on top of your applications with our Chrome extension. Track status changes and get insights on 
              when firms are sending out interview invitations.
            </p>
            <ul className="feature-list">
              <li>Real-time status updates</li>
              <li>Anonymous data on offer timelines</li>
              <li>Interview preparation guides</li>
              <li>Notifications for application deadlines</li>
              <li>Comparative insights on application journey</li>
            </ul>
            <a 
              href="https://chromewebstore.google.com/detail/talentlex-extension/eillgeanejhcfgibpllnijadpkpmfmmg" 
              className="secondary-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Chrome Extension
            </a>
          </motion.div>

          <motion.div variants={fadeIn} className="feature-block">
            <div className="feature-icon-large">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="3" y1="9" x2="21" y2="9"></line>
                <line x1="9" y1="21" x2="9" y2="9"></line>
              </svg>
            </div>
            <h2>Firm-Specific Insights</h2>
            <p>
              Tailor your applications to specific firms with our database of firm-specific insights and cultural information.
            </p>
            <ul className="feature-list">
              <li>Firm culture and values analysis</li>
              <li>Recent case studies and deals</li>
              <li>Application success patterns by firm</li>
              <li>Personalized recommendations</li>
              <li>Interview question databases</li>
            </ul>
          </motion.div>
          
          <motion.div variants={fadeIn} className="feature-block">
            <div className="feature-icon-large">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
            </div>
            <h2>Application Calendar</h2>
            <p>
              Never miss a deadline with our comprehensive application calendar, covering all major UK law firms.
            </p>
            <ul className="feature-list">
              <li>Deadline reminders for vacation schemes</li>
              <li>Training contract application timelines</li>
              <li>Custom notifications and alerts</li>
              <li>Syncs with Google Calendar and Outlook</li>
              <li>Application progress tracking</li>
            </ul>
          </motion.div>
          
          <motion.div variants={fadeIn} className="feature-block">
            <div className="feature-icon-large">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
            <h2>Peer Review Network</h2>
            <p>
              Connect with fellow law students and professionals to get human feedback on your applications.
            </p>
            <ul className="feature-list">
              <li>Anonymous peer review system</li>
              <li>Feedback from qualified lawyers</li>
              <li>Collaborative improvement suggestions</li>
              <li>Mock interview practice</li>
              <li>Networking opportunities</li>
            </ul>
          </motion.div>
          
          <motion.div variants={fadeIn} className="feature-block">
            <div className="feature-icon-large">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 20h9"></path>
                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
              </svg>
            </div>
            <h2>Automated Draft Generation</h2>
            <p>
              Start your application with AI-generated drafts tailored to your experience and target firm.
            </p>
            <ul className="feature-list">
              <li>Personalized application starters</li>
              <li>Customizable templates</li>
              <li>Firm-specific language adaptation</li>
              <li>Experience-matching suggestions</li>
              <li>Multiple variation options</li>
            </ul>
          </motion.div>
        </motion.div>

        <motion.div
          className="cta-container"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2>Ready to get started?</h2>
          <p>Join thousands of law students who have improved their applications with TalentLex.</p>
          <Link to="/signup" className="primary-button">Sign Up Now</Link>
        </motion.div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">TalentLex</div>
          <div className="footer-links">
            <Link to="/landing/privacy">Privacy Policy</Link>
            <Link to="/landing/terms">Terms of Service</Link>
            <Link to="/landing/contact">Contact Us</Link>
          </div>
          <div className="social-links">
            <a href="https://linkedin.com/company/talentlex" className="linkedin-link" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          </div>
        </div>
        <div className="copyright">© {new Date().getFullYear()} TalentLex. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default Features;
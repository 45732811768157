import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import './LandingPage.css';

const Terms = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  return (
    <div className="landing-page">
      <Navbar />
      
      <div className="subpage-container">
        <motion.div 
          className="subpage-header"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h1>Terms of Service</h1>
          <p>Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
        </motion.div>
        
        <motion.div 
          className="policy-content"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="policy-section">
            <h2>1. Introduction</h2>
            <p>
              Welcome to TalentLex. These terms and conditions outline the rules and regulations for the use of TalentLex's website and services.
            </p>
            <p>
              By accessing this website, we assume you accept these terms and conditions. Do not continue to use TalentLex if you do not agree to take all of the terms and conditions stated on this page.
            </p>
            <p>
              The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to TalentLex. "Party", "Parties", or "Us", refers to both the Client and ourselves.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>2. License to Use</h2>
            <p>
              Unless otherwise stated, TalentLex and/or its licensors own the intellectual property rights for all material on TalentLex. All intellectual property rights are reserved.
            </p>
            <p>
              You may view and/or print pages from the website for your own personal use subject to restrictions set in these terms and conditions.
            </p>
            <p>You must not:</p>
            <ul>
              <li>Republish material from this website</li>
              <li>Sell, rent or sub-license material from this website</li>
              <li>Reproduce, duplicate or copy material from this website</li>
              <li>Redistribute content from TalentLex (unless content is specifically made for redistribution)</li>
            </ul>
          </div>
          
          <div className="policy-section">
            <h2>3. User Accounts</h2>
            <p>
              When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
            </p>
            <p>
              You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>4. Subscription and Payments</h2>
            <p>
              Some parts of the Service are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis (such as monthly or annually), depending on the type of subscription plan you select.
            </p>
            <p>
              At the end of each period, your subscription will automatically renew under the exact same conditions unless you cancel it or TalentLex cancels it.
            </p>
            <p>
              You may cancel your subscription either through your online account management page or by contacting our customer support team.
            </p>
            <p>
              A valid payment method, including credit card, is required to process the payment for your subscription. You shall provide TalentLex with accurate and complete billing information, including full name, address, state, zip code, telephone number, and valid payment method information.
            </p>
            <p>
              If automatic billing fails to occur for any reason, TalentLex will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>5. Content and Services</h2>
            <p>
              Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, or other material. You are responsible for the content that you post on or through the Service, including its legality, reliability, and appropriateness.
            </p>
            <p>
              TalentLex does not claim ownership of your content but requires a license from you in order to use your content to provide the Service.
            </p>
            <p>
              When you upload content to the site, you grant us the right to use, modify, perform, display, reproduce, and distribute such content on and through the Service.
            </p>
            <p>
              You represent and warrant that: (i) the content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>6. AI-Powered Reviews</h2>
            <p>
              TalentLex uses artificial intelligence to review and provide feedback on your applications. While we strive for accuracy and helpfulness, we cannot guarantee that our AI-powered reviews will always be comprehensive or error-free.
            </p>
            <p>
              The AI-powered reviews are provided as a tool to help you improve your applications, but should not be considered as legal or professional advice. The decision to implement any suggestions provided by our AI system is ultimately yours.
            </p>
            <p>
              By using our AI-powered review services, you acknowledge that the results are generated by an automated system and may require human interpretation and judgment.
            </p>
            <p>
              You agree that the application materials you submit may be used in an anonymized form to train and improve our AI models. No personally identifiable information will be used for AI training purposes.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>7. Free Trial</h2>
            <p>
              TalentLex may, at its sole discretion, offer a subscription with a free trial for a limited period of time.
            </p>
            <p>
              You may be required to enter your billing information in order to sign up for the free trial.
            </p>
            <p>
              If you do enter your billing information when signing up for a free trial, you will not be charged by TalentLex until the free trial has expired. On the last day of the free trial period, unless you cancelled your subscription, you will be automatically charged the applicable subscription fee for the type of subscription you have selected.
            </p>
            <p>
              At any time and without notice, TalentLex reserves the right to (i) modify the terms and conditions of the free trial offer, or (ii) cancel such free trial offer.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>8. Limitation of Liability</h2>
            <p>
              In no event shall TalentLex, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
            </p>
            <ul>
              <li>Your access to or use of or inability to access or use the Service;</li>
              <li>Any conduct or content of any third party on the Service;</li>
              <li>Any content obtained from the Service; and</li>
              <li>Unauthorized access, use or alteration of your transmissions or content</li>
            </ul>
            <p>
              Whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>9. Disclaimer</h2>
            <p>
              Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
            </p>
            <p>
              TalentLex, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>10. Changes to Terms</h2>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.
            </p>
            <p>
              By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>11. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <p>
              Email: chai@talentlex.app<br />
            </p>
          </div>
        </motion.div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">TalentLex</div>
          <div className="footer-links">
            <Link to="/landing/privacy">Privacy Policy</Link>
            <Link to="/landing/terms">Terms of Service</Link>
            <Link to="/landing/contact">Contact Us</Link>
          </div>
          <div className="social-links">
            <a href="https://linkedin.com/company/talentlex" className="linkedin-link" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          </div>
        </div>
        <div className="copyright">© {new Date().getFullYear()} TalentLex. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default Terms;
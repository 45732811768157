import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import './LandingPage.css';

const Privacy = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  return (
    <div className="landing-page">
      <Navbar />
      
      <div className="subpage-container">
        <motion.div 
          className="subpage-header"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h1>Privacy Policy</h1>
          <p>Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
        </motion.div>
        
        <motion.div 
          className="policy-content"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="policy-section">
            <h2>1. Introduction</h2>
            <p>
              Welcome to TalentLex. We respect your privacy and are committed to protecting your personal data. 
              This privacy policy will inform you about how we look after your personal data when you visit our website 
              and tell you about your privacy rights and how the law protects you.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>2. The Data We Collect About You</h2>
            <p>
              Personal data, or personal information, means any information about an individual from which that person can be identified. 
              It does not include data where the identity has been removed (anonymous data).
            </p>
            <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
            <ul>
              <li><strong>Identity Data</strong> includes first name, last name, username or similar identifier, and university or institution name.</li>
              <li><strong>Contact Data</strong> includes email address and telephone numbers.</li>
              <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
              <li><strong>Profile Data</strong> includes your username and password, your interests, preferences, feedback, and survey responses.</li>
              <li><strong>Usage Data</strong> includes information about how you use our website and services.</li>
              <li><strong>Application Data</strong> includes the content of your application materials that you submit for review.</li>
            </ul>
          </div>
          
          <div className="policy-section">
            <h2>3. How We Use Your Personal Data</h2>
            <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
            <ul>
              <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
              <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
              <li>Where we need to comply with a legal obligation.</li>
            </ul>
            <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
            <p>Specifically, we use your data for:</p>
            <ul>
              <li>Providing and maintaining our Service</li>
              <li>Notifying you about changes to our Service</li>
              <li>Allowing you to participate in interactive features of our Service when you choose to do so</li>
              <li>Providing customer support</li>
              <li>Gathering analysis or valuable information so that we can improve our Service</li>
              <li>Monitoring the usage of our Service</li>
              <li>Detecting, preventing and addressing technical issues</li>
              <li>Improving our AI models to provide better application reviews and feedback</li>
            </ul>
          </div>
          
          <div className="policy-section">
            <h2>4. Data Security</h2>
            <p>
              We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, 
              altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. 
              They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
            </p>
            <p>
              We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>5. Data Retention</h2>
            <p>
              We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, 
              including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. 
              We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect of our relationship with you.
            </p>
            <p>
              To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, 
              the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data 
              and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>6. Your Legal Rights</h2>
            <p>
              Under certain circumstances, you have rights under data protection laws in relation to your personal data. These include the right to:
            </p>
            <ul>
              <li>Request access to your personal data.</li>
              <li>Request correction of your personal data.</li>
              <li>Request erasure of your personal data.</li>
              <li>Object to processing of your personal data.</li>
              <li>Request restriction of processing your personal data.</li>
              <li>Request transfer of your personal data.</li>
              <li>Right to withdraw consent.</li>
            </ul>
            <p>
              If you wish to exercise any of the rights set out above, please contact us at privacy@talentlex.com.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>7. Third-Party Links</h2>
            <p>
              This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may 
              allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. 
              When you leave our website, we encourage you to read the privacy policy of every website you visit.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>8. AI and Machine Learning</h2>
            <p>
              TalentLex uses artificial intelligence and machine learning technologies to provide application review services. When you submit applications for review, 
              we may use this data (in an anonymized form) to train our AI models to improve our services. Your personal information is not used to train these models, 
              only the content of the applications themselves, with all identifying information removed.
            </p>
          </div>
          
          <div className="policy-section">
            <h2>9. Contact Us</h2>
            <p>
              If you have any questions about this privacy policy or our privacy practices, please contact us at:
            </p>
            <p>
              Email: chai@talentlex.app<br />
            </p>
          </div>
        </motion.div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">TalentLex</div>
          <div className="footer-links">
            <Link to="/landing/privacy">Privacy Policy</Link>
            <Link to="/landing/terms">Terms of Service</Link>
            <Link to="/landing/contact">Contact Us</Link>
          </div>
          <div className="social-links">
            <a href="https://linkedin.com/company/talentlex" className="linkedin-link" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          </div>
        </div>
        <div className="copyright">© {new Date().getFullYear()} TalentLex. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default Privacy;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Navbar from './Navbar';
import './LandingPage.css';

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState([0]); // First one open by default

  const toggleQuestion = (index) => {
    if (openQuestions.includes(index)) {
      setOpenQuestions(openQuestions.filter(i => i !== index));
    } else {
      setOpenQuestions([...openQuestions, index]);
    }
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const faqItems = [
    {
      question: "What is TalentLex?",
      answer: "TalentLex is an AI-powered platform that helps law students improve their applications for vacation schemes and training contracts. Our tools analyze your applications and provide personalized feedback to increase your chances of success."
    },
    {
      question: "How does the application review work?",
      answer: "Our AI analyzes your application against a database of successful applications. It identifies strengths and weaknesses in your writing, highlights areas for improvement, and provides specific recommendations to enhance your application. The system has been trained on real successful applications to top law firms."
    },
    {
      question: "Is my data secure?",
      answer: "Yes, we take data security very seriously. All your personal information and application data is encrypted and stored securely. We never share your data with third parties without your consent. For more information, please review our Privacy Policy."
    },
    {
      question: "How much does TalentLex cost?",
      answer: "TalentLex offers both free and premium plans. Our free plan allows you to access basic application review features, while our premium plans offer more comprehensive analysis, unlimited reviews, and additional features like firm-specific insights. Visit our pricing page for more details."
    },
    {
      question: "What is the Chrome extension for?",
      answer: "Our Chrome extension helps you track the status of your applications across different law firms. It also provides anonymous data on when firms are sending out interview invitations and offers, giving you valuable insights into the application timeline."
    },
    {
      question: "Can I get a refund if I'm not satisfied?",
      answer: "Yes, we offer a 14-day money-back guarantee for all premium subscriptions. If you're not satisfied with our service, you can request a refund within 14 days of your purchase by contacting our support team."
    },
    {
      question: "How accurate is the AI feedback?",
      answer: "Our AI has been trained on a large dataset of successful and unsuccessful applications to provide accurate feedback. However, AI is a tool to assist you, not replace human judgment. We recommend using the AI feedback as a guide and combining it with feedback from mentors or career advisors for the best results."
    },
    {
      question: "How do I cancel my subscription?",
      answer: "You can cancel your subscription at any time through your account settings page. After cancellation, you'll continue to have access to the premium features until the end of your current billing cycle."
    },
    {
      question: "Can TalentLex help with interviews as well?",
      answer: "Currently, our primary focus is on improving written applications. However, we do offer some resources for interview preparation, including common questions and tips specific to law firm interviews. We're also working on developing more comprehensive interview preparation tools for future releases."
    },
    {
      question: "Which law firms does TalentLex work with?",
      answer: "TalentLex is an independent platform and is not affiliated with any specific law firms. Our database includes insights on applications to a wide range of firms, from Magic Circle and Silver Circle to US firms and regional practices across the UK."
    }
  ];

  return (
    <div className="landing-page">
      <Navbar />
      
      <div className="subpage-container">
        <motion.div 
          className="subpage-header"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h1>Frequently Asked Questions</h1>
          <p>Find answers to common questions about TalentLex</p>
        </motion.div>
        
        <motion.div 
          className="faq-container"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          {faqItems.map((item, index) => (
            <div 
              key={index}
              className={`faq-accordion-item ${openQuestions.includes(index) ? 'open' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              <div className="faq-question">
                <h3>{item.question}</h3>
                {openQuestions.includes(index) ? 
                  <ChevronUp size={20} /> : 
                  <ChevronDown size={20} />
                }
              </div>
              <AnimatePresence>
                {openQuestions.includes(index) && (
                  <motion.div 
                    className="faq-answer"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p>{item.answer}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </motion.div>

        <div className="faq-cta">
          <h2>Still have questions?</h2>
          <p>Contact our support team for more information</p>
          <Link to="/landing/contact" className="primary-button">Contact Us</Link>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">TalentLex</div>
          <div className="footer-links">
            <Link to="/landing/privacy">Privacy Policy</Link>
            <Link to="/landing/terms">Terms of Service</Link>
            <Link to="/landing/contact">Contact Us</Link>
          </div>
          <div className="social-links">
            <a href="https://linkedin.com/company/talentlex" className="linkedin-link" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          </div>
        </div>
        <div className="copyright">© {new Date().getFullYear()} TalentLex. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default FAQ;
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './LandingPage.css';
import Navbar from './Navbar';

// Images with exact paths as specified
import dechert from '../../firm_logos/dechert.png';
import dlaPiper from '../../firm_logos/dla-piper.png';
import cliffordChance from '../../firm_logos/clifford-chance.png';
import kirklandEllis from '../../firm_logos/kirkland-ellis.png';
import paulWeiss from '../../firm_logos/paul-weiss.png';

const LandingPage = () => {
  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  return (
    <div className="landing-page">
      <Navbar />

      <div className="hero-section">
        <div className="hero-content">
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            className="hero-title"
          >
            TalentLex AI reviews vacation scheme & training contracts applications.
          </motion.h1>
          
          <motion.p
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            className="hero-subtitle"
          >
            Get personalized feedback and improve your chances of securing a training contract at top law firms.
          </motion.p>
          
          <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="hero-cta"
        >
          <Link to="/signup" className="primary-button">Get Started</Link>
          <a href="#features" className="text-link">Learn more →</a>
        </motion.div>
        </div>
        
        <motion.div 
          className="hero-graphic"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          <div className="gradient-sphere"></div>
        </motion.div>
      </div>

      <motion.div 
        className="features-section" 
        id="features"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={staggerChildren}
      >
        <motion.h2 variants={fadeIn} className="section-title">Key Features</motion.h2>
        
        <div className="features-grid">
          <motion.div variants={fadeIn} className="feature-card">
            <div className="feature-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>
            <h3>Draft and review</h3>
            <p>Personalise your applications and optimise their scores before you submit.</p>
            <Link to="/signup" className="feature-cta">Get Started</Link>
          </motion.div>
          
          <motion.div variants={fadeIn} className="feature-card">
            <div className="feature-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
              </svg>
            </div>
            <h3>Track your application status</h3>
            <p>Download the Chrome extension to track when others are getting interviews from firms.</p>
            <a href="https://chromewebstore.google.com/detail/talentlex-extension/eillgeanejhcfgibpllnijadpkpmfmmg" className="secondary-button">Download Chrome Extension</a>
          </motion.div>
          
          <motion.div variants={fadeIn} className="feature-card">
            <div className="feature-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
                <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
                <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
            </div>
            <h3>AI-Powered Insights</h3>
            <p>Get detailed feedback on your applications with our advanced AI review system.</p>
            <Link to="/signup" className="feature-cta">Learn More</Link>
          </motion.div>
        </div>
      </motion.div>

      <motion.div 
        className="partner-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <h2>Trusted by applicants to top firms</h2>
        <div className="logo-slider">
          <div className="logo-slide-track">
            {/* Duplicate logos for continuous animation */}
            {[dlaPiper, dechert, cliffordChance, kirklandEllis, paulWeiss,
              dlaPiper, dechert, cliffordChance, kirklandEllis, paulWeiss].map((logo, index) => (
              <div className="logo-slide" key={index}>
                <img src={logo} alt="Law Firm Logo" className="firm-logo" />
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      <motion.div 
        className="faq-section" 
        id="faq"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerChildren}
      >
        <motion.h2 variants={fadeIn} className="section-title">Frequently Asked Questions</motion.h2>
        
        <div className="faq-list">
          <motion.div variants={fadeIn} className="faq-item">
            <h4>How does TalentLex improve my applications?</h4>
            <p>TalentLex uses AI trained on successful applications to provide personalized feedback on your application, highlighting strengths and suggesting improvements.</p>
          </motion.div>
          
          <motion.div variants={fadeIn} className="faq-item">
            <h4>Is my data secure?</h4>
            <p>We take data security seriously. All your applications are encrypted and we never share your personal information with third parties.</p>
          </motion.div>
          
          <motion.div variants={fadeIn} className="faq-item">
            <h4>How much does it cost?</h4>
            <p>TalentLex offers both free and premium plans. The basic review features are free, while our advanced analytics and unlimited reviews are available with TalentLex Plus.</p>
          </motion.div>
        </div>
      </motion.div>

      <motion.div 
        className="cta-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <h2>Ready to improve your applications?</h2>
        <p>Join thousands of law students who have improved their applications with TalentLex.</p>
        <Link to="/signup" className="primary-button">Get Started Now</Link>
      </motion.div>

      <footer className="footer" id="contact">
        <div className="footer-content">
          <div className="landing-logo">TalentLex</div>
          <div className="footer-links">
            <a href="/landing/privacy">Privacy Policy</a>
            <a href="/landing/terms">Terms of Service</a>
            <a href="/landing/contact">Contact Us</a>
          </div>
          <div className="social-links">
            <a href="https://www.linkedin.com/company/talentlexai/" className="linkedin-link" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </a>
          </div>
        </div>
        <div className="copyright">© {new Date().getFullYear()} TalentLex. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default LandingPage;